import { ChangeDetectorRef, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  Baggage,
  CalcOnlyAviaPackageRequest,
  CalcOnlyAviaPackageResponse,
  FlightPassengerOut,
  FlightSegment,
  OnlyAviaPackage,
  RefreshPriceResultEnum
} from '@appTypes/api.types';
import { ApiErrorResponse } from '@core/interceptors/api.interceptor';
import { StoreService } from '@core/store/store.service';
import { SVGIcon, SVG_ICONS } from '@data/svg-icons.data';
import { AviaApiService } from '@pages/avia-page/services/avia-api.service';
import { AviaSearchFormService } from '@pages/avia-page/services/avia-search-form.service';
import { cartAddItem } from '@shared/modules/cart/state/cart.actions';
import { ConfirmService } from '@shared/modules/confirm/confirm.service';
import { ConfirmParams, ConfirmType } from '@shared/modules/confirm/confirm.types';
import { DYNAMIC_MODAL_DATA_TOKEN } from '@shared/modules/dynamic-modal/dynamic-modal.const';
import { DynamicModalRef } from '@shared/modules/dynamic-modal/dynamic-modal.types';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-avia-result-details-desktop',
  templateUrl: './avia-result-details-desktop.component.html',
  styleUrl: './avia-result-details-desktop.component.scss'
})
export class AviaResultDetailsDesktopComponent {
  @ViewChild('modalFooterTemplateRef') public modalFooterTemplateRef!: TemplateRef<unknown>;
  private modalRef!: DynamicModalRef<AviaResultDetailsDesktopComponent>;

  flightSegmentDetailsTypedToken!: {
    segments: FlightSegment[];
    ticketsLeft: number;
    isBlock: boolean;
  };

  aviaPackage: OnlyAviaPackage | null = null;
  public bagageTypedToken!: Baggage;
  public bagageSelectorTemplateTypedToken!: { option: Baggage };
  public passengers: FlightPassengerOut[] | null = null;
  public isLoading = true;
  public readonly formGroup = new FormGroup({
    passengersForwardBaggages: new FormArray<
      FormGroup<{
        id: FormControl<string | null>;
        baggageCode: FormControl<string | null>;
      }>
    >([]),
    passengersBackwardBaggages: new FormArray<
      FormGroup<{
        id: FormControl<string | null>;
        baggageCode: FormControl<string | null>;
      }>
    >([])
  });
  public get passengersForwardBaggagesFormArray() {
    return this.formGroup.controls.passengersForwardBaggages;
  }
  public get passengersBackwardBaggagesFormArray() {
    return this.formGroup.controls.passengersBackwardBaggages;
  }
  constructor(
    @Inject(DYNAMIC_MODAL_DATA_TOKEN) public data: OnlyAviaPackage,
    private storeService: StoreService,
    private aviaApiService: AviaApiService,
    private searchService: AviaSearchFormService,
    private confirmService: ConfirmService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.calcPackage(
      {
        packageId: this.data?.id
        // refreshPrices: true
      },
      true
    );
  }

  getFlightPassengersPayload(baggageCode?: string | null) {
    return this.passengers?.map((p, i) => {
      return {
        id: p.id,
        selectedForwardBaggageCode: baggageCode || this.passengersForwardBaggagesFormArray.at(i)?.value.baggageCode,
        selectedBackwardBaggageCode: baggageCode || this.passengersBackwardBaggagesFormArray.at(i)?.value.baggageCode
      };
    });
  }
  onBagageChange(bagage: Baggage) {
    if (this.aviaPackage?.flight?.sameBaggageForAll) {
      const selectedBagages = this.passengers?.map((p) => ({ id: p.id, baggageCode: bagage.baggageCode }));
      this.formGroup.patchValue({
        passengersForwardBaggages: selectedBagages,
        passengersBackwardBaggages: selectedBagages
      });
    }
    if (!this.isLoading) {
      this.isLoading = true;
      this.calcPassengersBagage();
    }
  }

  onCheckBagage({ checked }: MatCheckboxChange) {
    this.isLoading = true;
    this.calcPassengersBagage(checked ? this.passengers?.[0].forwardBaggages?.[0].baggageCode || null : null);
  }

  calcPassengersBagage(baggageCode?: string | null) {
    const flightPassengers = this.getFlightPassengersPayload(baggageCode);
    this.formGroup.disable();
    this.calcPackage({ flightPassengers, packageId: this.aviaPackage?.id });
  }

  private showErrorModal(message: string, params: ConfirmParams | ConfirmType, refreshResults = false) {
    this.confirmService.confirm(message, params).subscribe(() => {
      if (refreshResults) {
        this.modalRef.close();
        this.searchService.onSubmit();
      }
    });
  }

  private handleRefreshPriceResult(response: CalcOnlyAviaPackageResponse, checkPrice = false) {
    switch (response?.refreshPriceResult) {
      case RefreshPriceResultEnum.HotelPriceNoMoreAvailable:
        this.showErrorModal('Hotel price no more available', 'error', true);
        break;
      case RefreshPriceResultEnum.FlightPriceNoMoreAvailable:
        this.showErrorModal('Flight price no more available', 'error', true);
        break;
      default:
        this.updatePackage(response);
        break;
    }
    if (response?.refreshPriceResult === RefreshPriceResultEnum.Success && checkPrice) {
      const totalPrice = response?.package?.totalPrice;
      const existingPrice = this.data.totalPrice;

      if (totalPrice && existingPrice && existingPrice > totalPrice) {
        this.confirmService.confirm('Price has been reduced', 'info');
      } else if (totalPrice && existingPrice && existingPrice < totalPrice) {
        this.confirmService.confirm('Price has been increased', 'info');
      }
    }
  }
  fillBagageArray(bagageCode?: string | null) {
    this.passengersForwardBaggagesFormArray.clear();
    this.passengersBackwardBaggagesFormArray.clear();
    if (!this.passengers?.length) return;
    this.passengers.forEach((p) => {
      this.passengersForwardBaggagesFormArray.push(
        new FormGroup({
          id: new FormControl<string | null>(p.id || null),
          baggageCode: new FormControl<string | null>(bagageCode || p.selectedForwardBaggageCode || null)
        })
      );
    });
    this.passengers.forEach((p) => {
      this.passengersBackwardBaggagesFormArray.push(
        new FormGroup({
          id: new FormControl<string | null>(p.id || null),
          baggageCode: new FormControl<string | null>(p.selectedBackwardBaggageCode || null)
        })
      );
    });
  }

  updatePackage(data: CalcOnlyAviaPackageResponse) {
    this.passengers = data.package?.flight?.passengers || null;

    if (this.passengersForwardBaggagesFormArray.value.length !== data.package?.flight?.passengers?.length) {
      this.fillBagageArray();
    }
    this.aviaPackage = data.package || null;

    this.isLoading = false;
    this.changeDetectorRef.markForCheck();
  }

  calcPackage(payload: CalcOnlyAviaPackageRequest, checkPrice = false) {
    this.aviaApiService
      .calcPackage(payload)
      .pipe(
        catchError((e: ApiErrorResponse) => {
          this.formGroup.enable();
          this.isLoading = false;
          this.aviaPackage = null;
          this.showErrorModal(
            e?.displayErrorMessage ??
              "It seems that something has gone wrong. Don't worry, our support team is ready to help you",
            { type: 'error', activityId: e.activityId }
          );
          return throwError(() => e);
        })
      )
      .subscribe((data) => {
        this.formGroup.enable();
        if (data.error) {
          this.showErrorModal(data.errorDescription || '', 'error');
          return;
        }
        this.handleRefreshPriceResult(data, checkPrice);
      });
  }

  private readonly svgIcons = SVG_ICONS;
  public getAirlineIconFn = (airlineId: string): SVGIcon => {
    const icon = `airline-logo-${airlineId?.toLowerCase()}` as SVGIcon;
    return this.svgIcons?.[icon] ? icon : 'airplane-outline-2';
  };

  public getMiddleSegments = (segments: FlightSegment[]) => {
    return segments?.filter((e, i) => i !== 0 && i !== segments.length);
  };

  public onAddCart() {
    if (!this.aviaPackage?.totalPrice || !this.aviaPackage?.priceCurrency) return;
    this.storeService.dispatch(
      new cartAddItem({
        type: 'avia',
        data: this.aviaPackage,
        price: this.aviaPackage.totalPrice || 0,
        priceCurrency: this.aviaPackage.priceCurrency || ''
      })
    );
    this.modalRef.close();
  }
}
