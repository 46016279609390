import { Injectable } from '@angular/core';
import { SearchOnlyAviaPackagesResponse } from '@appTypes/api.types';
import { AirportTreeItem } from '@appTypes/redefined-types';
import { IS_MOBILE, deepClean } from '@core/misc/misc.utils';
import { DataLoadStatusModel, RequestStateModel, StateFormModel } from '@core/store/store.models';
import { RequestStateParams } from '@core/store/store.types';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { AviaCommonService } from '@pages/avia-page/services/avia-common.service';
import { isEmpty } from 'lodash-es';
import { catchError, tap, throwError } from 'rxjs';
import {
  aviaGetAirports,
  aviaOpenDetails,
  aviaReset,
  aviaResetSearchResult,
  aviaSearchPackages,
  aviaSetResultFilter
} from './avia.actions';
import { AviaFilterFormModel, AviaPageDataModel, AviaStateModel } from './avia.model';

const defaults = new AviaStateModel();

@State<AviaStateModel>({
  name: 'avia',
  defaults
})
@Injectable()
export class AviaState {
  constructor(private common: AviaCommonService) {}

  @Action(aviaReset)
  hotelsReset({ setState }: StateContext<AviaStateModel>) {
    return setState(defaults);
  }
  @Action(aviaSearchPackages, { cancelUncompleted: true })
  aviaSearchPackages({ setState, getState, patchState }: StateContext<AviaStateModel>, { paging }: aviaSearchPackages) {
    const {
      searchResults: { loadState },
      searchForm
    } = getState();
    if (loadState.status === 'loading') return;

    if (!searchForm.model) return;

    const payload = deepClean(searchForm.model);
    if (isEmpty(payload)) return;
    if (!IS_MOBILE) !paging?.pagingId && this.common.loadingWindow.show();

    if (!paging?.pagingId)
      patchState({
        searchResults: new RequestStateModel<SearchOnlyAviaPackagesResponse>(new DataLoadStatusModel('loading')),
        filterForm: new StateFormModel(new AviaFilterFormModel()) //reset filters
      });
    else
      setState(
        patch<AviaStateModel>({
          searchResults: patch<RequestStateModel<SearchOnlyAviaPackagesResponse>>({
            loadState: new DataLoadStatusModel('loading')
          })
        })
      );
    return this.common.api.searchOnlyAviaPackages({ ...payload, ...paging }).pipe(
      tap((response) => {
        patchState({
          searchResults:
            !paging?.pagingId && !response.packages?.length
              ? new RequestStateModel<SearchOnlyAviaPackagesResponse>(
                  new DataLoadStatusModel('error', 'Data not found to show. try searching with different parameters')
                )
              : new RequestStateModel<SearchOnlyAviaPackagesResponse>(response)
        });
        if (!IS_MOBILE) !paging?.pagingId && this.common.loadingWindow.hide();
      }),
      catchError((e) => {
        if (!paging?.pagingId)
          patchState({
            searchResults: new RequestStateModel<SearchOnlyAviaPackagesResponse>(
              new DataLoadStatusModel('error', e?.displayErrorMessage)
            )
          });
        else
          setState(
            patch<AviaStateModel>({
              searchResults: patch<RequestStateModel<SearchOnlyAviaPackagesResponse>>({
                loadState: new DataLoadStatusModel('error', e?.displayErrorMessage)
              })
            })
          );
        if (!IS_MOBILE) !paging?.pagingId && this.common.loadingWindow.hide();
        return throwError(() => e);
      })
    );
  }

  @Action(aviaResetSearchResult)
  aviaResetSearchResult({ patchState }: StateContext<AviaStateModel>) {
    patchState({
      searchResults: new RequestStateModel<SearchOnlyAviaPackagesResponse>()
    });
  }
  @Action(aviaGetAirports, { cancelUncompleted: true })
  aviaGetAirports({ getState, setState }: StateContext<AviaStateModel>, { refresh }: aviaGetAirports) {
    const {
      pageData: { airports }
    } = getState();

    if (airports.loadState.status === 'completed' && !refresh) return;

    const updateState = (stateParams: RequestStateParams<AirportTreeItem[]>) => {
      setState(
        patch<AviaStateModel>({
          pageData: patch<AviaPageDataModel>({ airports: new RequestStateModel(stateParams) })
        })
      );
    };

    updateState(new DataLoadStatusModel('loading'));

    return this.common.catalougeApi.getAirportTree().pipe(
      tap((response) => updateState(response)),
      catchError((e) => {
        updateState(
          new DataLoadStatusModel(
            'error',
            e?.displayErrorMessage ??
              "It seems that something has gone wrong. Don't worry, our support team is ready to help you"
          )
        );
        return throwError(() => e);
      })
    );
  }

  @Action(aviaOpenDetails, { cancelUncompleted: true })
  aviaOpenDetails(ctx: StateContext<AviaStateModel>, { data }: aviaOpenDetails) {
    if (!data) return;
    this.common.openDetails(data);
  }

  @Action(aviaSetResultFilter, { cancelUncompleted: true })
  aviaSetResultFilter({ patchState }: StateContext<AviaStateModel>, { data }: aviaSetResultFilter) {
    patchState({
      filterForm: new StateFormModel(data)
    });
  }
  /* Selectors */

  @Selector([AviaState])
  static airports({ pageData: { airports } }: AviaStateModel) {
    return airports;
  }

  @Selector([AviaState])
  static searchResults({ searchResults, filterForm: { model: filterParams } }: AviaStateModel) {
    if (filterParams && searchResults.response?.packages) {
      let packages = searchResults.response.packages;

      if (filterParams.airlines?.length)
        packages = packages.filter(
          (f) =>
            f.flight?.departureSegments?.some((d) => d?.airlineCode && filterParams.airlines.includes(d.airlineCode)) ||
            (f.flight?.returnSegments?.length &&
              f.flight?.returnSegments?.some((r) => r?.airlineCode && filterParams.airlines.includes(r.airlineCode)))
        );
      if (filterParams.stopsType === 'nonstop')
        packages = packages.filter(
          (e) =>
            e?.flight?.departureSegments?.length === 1 &&
            (e?.flight?.returnSegments?.length ? e?.flight?.returnSegments?.length === 1 : true)
        );
      else if (filterParams.stopsType === '1stop')
        packages = packages.filter(
          (e) =>
            e?.flight?.departureSegments?.length === 2 &&
            (e?.flight?.returnSegments?.length ? e?.flight?.returnSegments?.length === 2 : true)
        );

      return { ...searchResults, response: { ...searchResults.response, packages } };
    }
    return searchResults;
  }
  @Selector([AviaState])
  static isResult({ searchResults }: AviaStateModel) {
    return Boolean(searchResults?.response?.packages?.length);
  }

  static findLocationName(code: string) {
    return createSelector([AviaState], ({ pageData: { airports } }: AviaStateModel): string | null => {
      if (!airports?.response?.length) return null;
      return airports?.response?.find((c) => c.value === code)?.label ?? null;
    });
  }

  @Selector([AviaState])
  static searchFormData({ searchForm: { model } }: AviaStateModel) {
    return model;
  }
  @Selector([AviaState])
  static searchLoadStatus({ searchResults: { loadState } }: AviaStateModel): DataLoadStatusModel {
    return loadState;
  }
}
