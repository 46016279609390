import { SearchOnlyAviaPackagesResponse } from '@appTypes/api.types';
import { ValuesOfConst } from '@core/misc/utils-types.misc';
import { AVIA_TYPES_STATIC_DATA } from './avia-page.const';

export type SearchOnlyAviaPackagePaging = Pick<SearchOnlyAviaPackagesResponse, 'pageNumber' | 'pagingId'>;
export type AviaFilterStopsType = 'any' | 'nonstop' | '1stop';

export type FlightType = (typeof AVIA_TYPES_STATIC_DATA)[0];
export type FlightTicketTypeValues = ValuesOfConst<typeof AVIA_TYPES_STATIC_DATA>['value'];
export type FilterFlightType = Exclude<FlightTicketTypeValues, 'multipleFlights'>;

export enum TicketTypesEnum {
  'First' = 'F',
  'Business' = 'B',
  'Premium' = 'P',
  'Coach' = 'C' // @note - C === Economy
}
