import { OnlyAviaPackage } from '@appTypes/api.types';
import { SearchOnlyAviaPackagePaging } from '@pages/avia-page/avia-page.types';
import { AviaFilterFormModel } from '@pages/avia-page/state/avia.model';

export class aviaReset {
  static type = '[Avia] Reset';
  constructor() {}
}
export class aviaSearchPackages {
  static readonly type = '[Avia] SearchPackages';
  constructor(public paging?: SearchOnlyAviaPackagePaging) {}
}

export class aviaResetSearchResult {
  static readonly type = '[Avia] aviaResetSearchResult';
}

export class aviaGetAirports {
  static readonly type = '[Avia] GetAirports';
  constructor(public refresh = false) {}
}

export class aviaOpenDetails {
  static readonly type = '[Avia] OpenDetails';
  constructor(public data: OnlyAviaPackage) {}
}

export class aviaSetResultFilter {
  static readonly type = '[Avia] SetResultFilter';
  constructor(public data: AviaFilterFormModel) {}
}
