import { SearchOnlyAviaPackagesRequest, SearchOnlyAviaPackagesResponse } from '@appTypes/api.types';
import { AirportTreeItem } from '@appTypes/redefined-types';
import { DataLoadStatusModel, RequestStateModel, StateFormModel } from '@core/store/store.models';
import { AviaFilterStopsType } from '@pages/avia-page/avia-page.types';

export class AviaPageDataModel {
  airports = new RequestStateModel<AirportTreeItem[]>();
}
export class AviaFilterFormModel {
  airlines: string[] = [];
  stopsType: AviaFilterStopsType = 'any';
}
export class AviaStateModel {
  searchForm = new StateFormModel<SearchOnlyAviaPackagesRequest>();
  searchLoadStatus = new DataLoadStatusModel();
  searchResults = new RequestStateModel<SearchOnlyAviaPackagesResponse>();
  filterForm = new StateFormModel(new AviaFilterFormModel());
  pageData = new AviaPageDataModel();
}
