import { IS_MOBILE } from '@core/misc/misc.utils';

export const NGXS_AVIA_PERSISTENT_STATE_KEYS = ['avia.searchForm'];
export const NGXS_AVIA_SEARCH_FORM = 'avia.searchForm';
export const NGXS_AVIA_FILTER_FORM = 'avia.filterForm';

export const AVIA_RESULTS_LENGTH = IS_MOBILE ? 30 : 100;

export const AVIA_TYPES_STATIC_DATA = [
  {
    label: 'Round Trip',
    value: 'roundTrip'
  },
  {
    label: 'One Way',
    value: 'oneWay'
  },
  {
    label: 'Multiple Flights',
    value: 'multipleFlights'
  }
];
