import { Injectable } from '@angular/core';
import { OnlyAviaPackage } from '@appTypes/api.types';
import { IS_MOBILE } from '@core/misc/misc.utils';
import { AviaResultDetailsDesktopComponent } from '@pages/avia-page/desktop/avia-result-desktop/avia-result-details-desktop/avia-result-details-desktop.component';
import { AviaResultDetailsMobileComponent } from '@pages/avia-page/mobile/avia-result-mobile/avia-result-details-mobile/avia-result-details-mobile.component';
import { KzDesktopLoadingWindowService } from '@shared/components/desktop/kz-desktop-loading-window/kz-desktop-loading-window.service';
import { DynamicModalService } from '@shared/modules/dynamic-modal/dynamic-modal.service';
import { CatalogueApiService } from '@shared/services/api/catalogue-api.service';
import { AviaApiService } from './avia-api.service';

@Injectable()
export class AviaCommonService {
  constructor(
    public loadingWindow: KzDesktopLoadingWindowService,
    public api: AviaApiService,
    public catalougeApi: CatalogueApiService,
    private dynamicModalService: DynamicModalService
  ) {}

  public openDetails(data: OnlyAviaPackage) {
    if (IS_MOBILE)
      return this.dynamicModalService.open({
        title: 'Particulars',
        component: AviaResultDetailsMobileComponent,
        height: '85vh',
        data
      });
    else
      return this.dynamicModalService.open({
        title: 'Particulars',
        height: '720px',
        component: AviaResultDetailsDesktopComponent,
        data
      });
  }
}
