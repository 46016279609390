@if (isLoading && !aviaPackage) {
  <div class="flights-details-container">
    <mat-progress-bar mode="indeterminate" />
  </div>
}
@if (aviaPackage) {
  <div class="flights-details-container">
    <div class="flights-details-body">
      <form class="details" [formGroup]="formGroup">
        @if (
          aviaPackage.flight?.departureSegments?.[(aviaPackage.flight?.departureSegments?.length ?? 1) - 1];
          as lastSegment
        ) {
          <h4 class="mb-0">
            {{ aviaPackage.flight?.departureSegments?.[0]?.departureCityName ?? '' }} —
            {{ lastSegment.arrivalCityName ?? '' }}
          </h4>
        }
        <ng-container
          *ngTemplateOutlet="
            flightSegmentsRef;
            context: {
              segments: aviaPackage.flight?.departureSegments,
              ticketsLeft: aviaPackage.flight?.departureTicketsLeft,
              isBlock: aviaPackage.flight?.isBlock
            }
          "
        />
        @if (!aviaPackage.flight?.totalBaggage) {
          <ng-container formArrayName="passengersForwardBaggages">
            @for (
              passengersForwardBagageForm of passengersForwardBaggagesFormArray.controls;
              track index;
              let index = $index
            ) {
              <div class="mb-2" [formGroupName]="index">
                <app-kz-desktop-select
                  placeholder="Baggage not included"
                  optionValue="baggageCode"
                  formControlName="baggageCode"
                  class="other-package-selector"
                  [typedToken]="bagageTypedToken"
                  [options]="(passengers && passengers[index] && passengers[index].forwardBaggages) || []"
                  (selectedOptionData)="onBagageChange($event)"
                >
                  <ng-template #templateSelected let-option>
                    <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                  </ng-template>
                  <ng-template #templateOptionLabel let-option>
                    <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                  </ng-template>
                </app-kz-desktop-select>
              </div>
            }
          </ng-container>
        }
        @if (aviaPackage.flight?.returnSegments) {
          @if (
            aviaPackage.flight?.returnSegments?.[(aviaPackage.flight?.returnSegments?.length ?? 1) - 1];
            as lastSegment
          ) {
            <h4 class="mb-0 mt-3">
              {{ aviaPackage.flight?.returnSegments?.[0]?.departureCityName ?? '' }} —
              {{ lastSegment.arrivalCityName ?? '' }}
            </h4>
          }
          <ng-container
            *ngTemplateOutlet="
              flightSegmentsRef;
              context: {
                segments: aviaPackage.flight?.returnSegments,
                ticketsLeft: aviaPackage.flight?.returnTicketsLeft,
                isBlock: aviaPackage.flight?.isBlock
              }
            "
          />
          @if (!aviaPackage.flight?.totalBaggage) {
            <ng-container formArrayName="passengersBackwardBaggages">
              @for (
                passengersBackwardBaggageForm of passengersBackwardBaggagesFormArray.controls;
                track index;
                let index = $index
              ) {
                <div class="mb-2" [formGroupName]="index">
                  <app-kz-desktop-select
                    placeholder="Baggage not included"
                    optionValue="baggageCode"
                    formControlName="baggageCode"
                    class="other-package-selector"
                    [typedToken]="bagageTypedToken"
                    [options]="(passengers && passengers[index] && passengers[index].backwardBaggages) || []"
                    (selectedOptionData)="onBagageChange($event)"
                  >
                    <ng-template #templateSelected let-option>
                      <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                    </ng-template>
                    <ng-template #templateOptionLabel let-option>
                      <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                    </ng-template>
                  </app-kz-desktop-select>
                </div>
              }
            </ng-container>
          }
        }
        @if (aviaPackage.flight?.totalBaggage && passengers?.length) {
          @if (passengers?.[0]?.forwardBaggages?.[0]; as forwardBaggage) {
            <div>
              <mat-checkbox
                color="primary"
                [checked]="forwardBaggage?.included"
                [disabled]="forwardBaggage?.included"
                (change)="onCheckBagage($event)"
              >
                <span style="font-size: 0.9em"
                  >{{ forwardBaggage?.description }}
                  {{ forwardBaggage?.price ?? 0 | kzPrice: forwardBaggage?.currency }}
                  ({{ 'per person' | translate }}, {{ 'per segment' | translate }})
                </span>
              </mat-checkbox>
            </div>
          }
        }
      </form>
    </div>

    @if (data.flight?.departureSegments?.at(0)?.airlineCode === 'K@') {
      <h3 class="flex items-center">
        <mat-icon class="svg-airline" svgIcon="airline-logo-k@" />
        <a href="https://flyarystan.com/fare-types" class="text-kz-danger" target="_blank"
          >FlyArystan Important Notification!</a
        >
      </h3>
    }
  </div>
}
<ng-template #flyDirectionLine>
  <div class="fly-direction">
    <div class="vector h-full">
      <div class="icon" style="padding-bottom: 1.9rem">
        <mat-icon svgIcon="circle" class="circle-icon" />
      </div>
      <div class="icon pb-5">
        <mat-icon class="airplane-icon" color="primary" svgIcon="airplane-res" />
      </div>
      <div class="icon">
        <mat-icon svgIcon="circle" class="circle-icon" />
      </div>
    </div>
  </div>
</ng-template>
<ng-template #timeDescription let-data="data">
  <div class="time">
    <div>{{ data.time | date: 'hh:mm a' }}</div>
    <div>{{ data.terminalCode }}</div>
    <div>{{ data.airportName }}</div>
  </div>
</ng-template>
<ng-template
  #flightSegmentsRef
  let-segments="segments"
  let-ticketsLeft="ticketsLeft"
  let-isBlock="isBlock"
  [typedTemplate]="flightSegmentDetailsTypedToken"
>
  @for (segment of segments; track index; let index = $index; let first = $first; let last = $last) {
    <div class="flight-container">
      @if (!first) {
        <div class="w-full bg-kz-layout-featured flex flex-row items-center py-2 mb-3">
          <div class="px-2">
            <mat-icon class="operator-icon" svgIcon="walking" color="primary" />
          </div>
          <div>
            <div>{{ 'Flight Stop in' | translate }} {{ segment.departureCityName ?? '' }}</div>
            <div class="text-secondary">{{ segment.flightDuration }}</div>
          </div>
        </div>
      }
      <div>{{ segment.departureFlightDate | date: 'dd MMMM, E' }}</div>
      <div class="flight-wrapper">
        <ng-container *ngTemplateOutlet="flyDirectionLine" />
        <div class="descriptions">
          <ng-container
            *ngTemplateOutlet="
              timeDescription;
              context: {
                data: {
                  time: segment.departureFlightDate,
                  airportName: segment.departureAirportName,
                  terminalCode: segment.departureAirportCode
                }
              }
            "
          />
          <div class="operator">
            <div class="chip">{{ segment.flightDuration }}</div>
            <mat-icon class="operator-icon" [svgIcon]="segment.airlineCode | runFunction: getAirlineIconFn" />
            <div class="chip">{{ segment.airlineName }}</div>
            <div class="chip">{{ 'Flight route' | translate }} {{ segment.flightCode }}</div>

            @if (segment.class) {
              <app-kz-flight-class [flightClass]="segment.class" [ticketsLeft]="ticketsLeft" [isBlock]="isBlock" />
            }
          </div>
          <ng-container
            *ngTemplateOutlet="
              timeDescription;
              context: {
                data: {
                  time: segment.arrivalFlightDate,
                  airportName: segment.arrivalAirportName,
                  terminalCode: segment.arrivalAirportCode
                }
              }
            "
          />
        </div>
      </div>
    </div>
  }
</ng-template>

<!-- Templates -->
<ng-template #bagageSelectOptionTemplateRef let-option="option" [typedTemplate]="bagageSelectorTemplateTypedToken">
  <div class="flex flex-row justify-between w-full">
    {{ option.description }} {{ option.price ?? 0 | kzPrice: option.currency }}
  </div>
</ng-template>

<ng-template #modalFooterTemplateRef>
  @if (isLoading && aviaPackage) {
    <div class="tour-details-container">
      <mat-progress-bar mode="indeterminate" />
    </div>
  }
  <div class="flights-details-footer">
    <div class="price-box">
      <h2>{{ aviaPackage?.totalPrice | kzPrice }}</h2>
    </div>
    <div>
      <button type="button" mat-flat-button color="primary" (click)="onAddCart()">
        {{ 'Book now' | translate }}
      </button>
    </div>
  </div>
</ng-template>
