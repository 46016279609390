@if (isLoading && !aviaPackage) {
  <div class="flights-details-container">
    <mat-progress-bar mode="indeterminate" />
  </div>
}
@if (aviaPackage) {
  <div class="flex w-full flex-col relative">
    <div class="h-full overflow-y-auto px-4 py-3 pb-24 text-sm">
      <form class="flex flex-col gap-1.5" [formGroup]="formGroup">
        @if (
          aviaPackage.flight?.departureSegments?.[(aviaPackage.flight?.departureSegments?.length ?? 1) - 1];
          as lastSegment
        ) {
          <h4>
            {{ aviaPackage.flight?.departureSegments?.[0]?.departureCityName ?? '' }}
            —
            {{ lastSegment.arrivalCityName ?? '' }}
          </h4>
        }
        <ng-container
          *ngTemplateOutlet="
            flightSegmentsRef;
            context: {
              segments: aviaPackage.flight?.departureSegments,
              ticketsLeft: aviaPackage.flight?.departureTicketsLeft,
              isBlock: aviaPackage.flight?.isBlock
            }
          "
        />
        @if (!aviaPackage.flight?.totalBaggage) {
          <ng-container formArrayName="passengersForwardBaggages">
            @for (
              passengersForwardBagageForm of passengersForwardBaggagesFormArray.controls;
              track index;
              let index = $index
            ) {
              <div [formGroupName]="index">
                <app-kz-desktop-select
                  placeholder="Bagage"
                  optionValue="baggageCode"
                  formControlName="baggageCode"
                  class="other-package-selector"
                  [typedToken]="bagageTypedToken"
                  [options]="(passengers && passengers[index] && passengers[index].forwardBaggages) || []"
                  (selectedOptionData)="onBagageChange($event)"
                >
                  <ng-template #templateSelected let-option>
                    <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                  </ng-template>
                  <ng-template #templateOptionLabel let-option>
                    <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                  </ng-template>
                </app-kz-desktop-select>
              </div>
            }
          </ng-container>
        }
        @if (aviaPackage.flight?.returnSegments) {
          <ng-container>
            @if (
              aviaPackage.flight?.returnSegments?.[(aviaPackage.flight?.returnSegments?.length ?? 1) - 1];
              as lastSegment
            ) {
              <h4 class="mb-0 mt-3">
                {{ aviaPackage.flight?.returnSegments?.[0]?.departureCityName ?? '' }}
                —
                {{ lastSegment.arrivalCityName ?? '' }}
              </h4>
            }
            <ng-container
              *ngTemplateOutlet="
                flightSegmentsRef;
                context: {
                  segments: aviaPackage.flight?.returnSegments,
                  ticketsLeft: aviaPackage.flight?.returnTicketsLeft,
                  isBlock: aviaPackage.flight?.isBlock
                }
              "
            />
          </ng-container>
          @if (!aviaPackage.flight?.totalBaggage) {
            <ng-container formArrayName="passengersBackwardBaggages">
              @for (
                passengersBackwardBaggageForm of passengersBackwardBaggagesFormArray.controls;
                track index;
                let index = $index
              ) {
                <div [formGroupName]="index">
                  <app-kz-desktop-select
                    placeholder="Bagage"
                    optionValue="baggageCode"
                    formControlName="baggageCode"
                    class="other-package-selector"
                    [typedToken]="bagageTypedToken"
                    [options]="(passengers && passengers[index] && passengers[index].backwardBaggages) || []"
                    (selectedOptionData)="onBagageChange($event)"
                  >
                    <ng-template #templateSelected let-option>
                      <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                    </ng-template>
                    <ng-template #templateOptionLabel let-option>
                      <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                    </ng-template>
                  </app-kz-desktop-select>
                </div>
              }
            </ng-container>
          }
        }
        @if (aviaPackage.flight?.totalBaggage && passengers?.length) {
          @if (passengers?.[0]?.forwardBaggages?.[0]; as forwardBaggage) {
            <div>
              <mat-checkbox
                color="primary"
                checked
                [disabled]="forwardBaggage?.included"
                (change)="onCheckBagage($event)"
              >
                <span style="font-size: 0.9em"
                  >{{ forwardBaggage?.description }}
                  {{ forwardBaggage?.price ?? 0 | kzPrice: forwardBaggage?.currency }}
                  ({{ 'per person' | translate }}, {{ 'per segment' | translate }})
                </span>
              </mat-checkbox>
            </div>
          }
        }
        @if (data.flight?.departureSegments?.at(0)?.airlineCode === 'K@') {
          <h3 class="flex items-center">
            <mat-icon class="svg-airline" svgIcon="airline-logo-k@" />
            <a href="https://flyarystan.com/fare-types" class="text-kz-danger" target="_blank"
              >FlyArystan Important Notification!</a
            >
          </h3>
        }
      </form>
    </div>
    <div class="dynamic-modal-footer z-10">
      @if (isLoading && aviaPackage) {
        <div class="tour-details-container">
          <mat-progress-bar mode="indeterminate" />
        </div>
      }
      <div class="dynamic-modal-actions">
        <button mat-flat-button color="primary" class="w-1/2" type="submit" enterkeyhint="go" (click)="onAddCart()">
          <mat-icon svgIcon="cart" />
          {{ aviaPackage.totalPrice | kzPrice }}
        </button>
      </div>
    </div>
  </div>
}

<ng-template #flyDirectionLine>
  <div class="fly-direction">
    <div class="vector h-full">
      <div class="icon" style="padding-bottom: 1.9rem">
        <div class="circle-icon"><span class="circle"></span></div>
      </div>
      <div class="icon pb-5">
        <mat-icon class="airplane-icon" color="primary" svgIcon="airplane-res" />
      </div>

      <div class="icon">
        <div class="circle-icon"><span class="circle"></span></div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #timeDescription let-data="data">
  <div class="time">
    <div>{{ data.time | date: 'hh:mm a' }}</div>
    <div>{{ data.terminalCode }}</div>
    <div>{{ data.airportName }}</div>
  </div>
</ng-template>

<ng-template
  #flightSegmentsRef
  let-segments="segments"
  let-ticketsLeft="ticketsLeft"
  let-isBlock="isBlock"
  [typedTemplate]="flightSegmentDetailsTypedToken"
>
  @for (segment of segments; track index; let index = $index; let first = $first; let last = $last) {
    <div class="flight-container bg-color bg-kz-light-blue-100">
      @if (!first) {
        <div class="w-full bg-kz-layout-featured flex flex-row items-center py-2 mb-3">
          <div class="px-2">
            <mat-icon class="operator-icon" svgIcon="walking" color="primary" />
          </div>
          <div>
            <div>{{ 'Flight Stop in' | translate }} {{ segment.departureCityName ?? '' }}</div>
            <div class="text-secondary">
              {{ segment.flightDuration }}
            </div>
          </div>
        </div>
      }

      <div>{{ segment.departureFlightDate | date: 'dd MMMM, E' }}</div>
      <div class="flight-wrapper">
        <ng-container *ngTemplateOutlet="flyDirectionLine" />
        <div class="descriptions">
          <ng-container
            *ngTemplateOutlet="
              timeDescription;
              context: {
                data: {
                  time: segment.departureFlightDate,
                  airportName: segment.departureAirportName,
                  terminalCode: segment.departureAirportCode
                }
              }
            "
          />
          <div class="operator">
            <div class="chip">{{ segment.flightDuration }}</div>
            <mat-icon class="operator-icon" [svgIcon]="segment.airlineCode | runFunction: getAirlineIconFn" />
            <div class="chip">{{ segment.airlineName }}</div>
            <div class="chip">{{ 'Flight route' | translate }} {{ segment.flightCode }}</div>
          </div>
          <ng-container
            *ngTemplateOutlet="
              timeDescription;
              context: {
                data: {
                  time: segment.arrivalFlightDate,
                  airportName: segment.arrivalAirportName,
                  terminalCode: segment.arrivalAirportCode
                }
              }
            "
          />
          <div class="signs">
            @if (segment.class) {
              <app-kz-flight-class [flightClass]="segment.class" [ticketsLeft]="ticketsLeft" [isBlock]="isBlock" />
            }
          </div>
        </div>
      </div>
    </div>
  }
</ng-template>

<ng-template #bagageSelectOptionTemplateRef let-option="option" [typedTemplate]="bagageSelectorTemplateTypedToken">
  <div class="flex flex-row justify-between w-full">
    {{ option.description }} {{ option.price ?? 0 | kzPrice: option.currency }}
  </div>
</ng-template>
